/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Inter:slnt,wght@-10..0,100..900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "styles/base";

@import "~@aws-amplify/ui-angular/theme.css";

[data-amplify-authenticator] {
  --amplify-components-authenticator-modal-width: 100vw;
  --amplify-components-authenticator-max-width: 100vw;
}

.hermes-input {
  @apply bg-gray-50 border border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500
  text-sm rounded block w-full p-1.5
  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500;
}

.hermes-input-label {
  @apply block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300;
}

.nav-item {
  @apply block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700;
}
.nav-item-active {
  @apply block py-2 pr-4 pl-3 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 dark:text-white;
}

.preformatted {
  @apply bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-white font-mono p-2 rounded-lg;
}
